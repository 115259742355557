import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import BackgroundImage from 'gatsby-background-image';
import Button from 'react-bootstrap/Button';
import LocalizedLink from '../../../components/LocalizedLink';
import SEO from '../../../components/SEO';
import Layout from '../../../components/Layout';

export default function SAPProjects({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 h-175-px"
        fluid={data.datArtSAPHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>
      {/* <Container className="p-4 min-vh-100"> */}
      <Container className="p-4">
        <p>{frontmatter.introText}</p>
        <div className="bg-grey w-100 p-4 mx-auto mt-4 rounded special-shadow">
          <h2 className="h4 text-info">{frontmatter.phase1Title}</h2>
          <p className="lead">{frontmatter.phase1P1Text}</p>
          <p className="lead">{frontmatter.phase1P2Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase2Title}</h2>
          <p className="lead">{frontmatter.phase2Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase3Title}</h2>
          <p className="lead">{frontmatter.phase3Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase4Title}</h2>
          <p className="lead">{frontmatter.phase4P1Text}</p>
          <p className="lead">{frontmatter.phase4P2Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase5Title}</h2>
          <p className="lead">
            {frontmatter.phase5Part1Text}
            <br />
            {frontmatter.phase5Part2Text}
          </p>
        </div>
        <p className="lead text-center mt-5 font-weight-bold text-primary">
          {frontmatter.contactText}
        </p>
        <p className="text-center">
          <LocalizedLink
            to="/contact/#contact"
            state={{ sourcePage: frontmatter.title }}
          >
            <Button>{frontmatter.contactButtonText}</Button>
          </LocalizedLink>
        </p>
      </Container>
    </Layout>
  );
}

SAPProjects.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`  
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "sap-services-project-management"}}) {
            frontmatter {
                title
                description

                mainTitle

                introText

                phase1Title
                phase1P1Text
                phase1P2Text
                phase2Title
                phase2Text
                phase3Title
                phase3Text
                phase4Title
                phase4P1Text
                phase4P2Text
                phase5Title
                phase5Part1Text
                phase5Part2Text

                contactText
                contactButtonText
            }
        }
        
        datArtSAPHeaderImage: file(relativePath: {regex: "/datArtSAPHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }
    }
`;
